import React from 'react';
import { GET_GROUP_BY, SET_SELECTED_GROUP, UNGROUPED, securityLegend, visibilityLegend, SET_SITEMAP_EXPAND_ACCORDION } from './NetSpaceConstant';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, Select } from "@material-ui/core";
import NetSpaceTable from './NetSpaceTable';
import { renderIsMonitored } from './NetSpaceUtils';
import Chip from '@material-ui/core/Chip';
import { startCase, toLower } from 'lodash';

const ControlPanel = () => {
    const dispatch = useDispatch();
    const groups = useSelector((state) => state.NetspaceReducer.groups);
    const selectedGroup = useSelector((state) => state.NetspaceReducer.selectedGroup);
    const groupCount = useSelector((state) => state.NetspaceReducer.groupCount);
    const accordionData = useSelector((state) => state.NetspaceReducer.accordionData);
    const expandAccordionState = useSelector((state) => state.NetspaceReducer.expandAccordion);
    useEffect(() => {
        dispatch({
            type: GET_GROUP_BY
        });
    }, []);
    const handleGroupByChange = (e) => {
        dispatch({
            type: SET_SELECTED_GROUP,
            payload: e.target.value
        });
    }
    const handleAccordionExpand = (accordionName) => {
        let expandAccordionUpdate = { ...expandAccordionState };
        expandAccordionUpdate[accordionName] = !expandAccordionUpdate[accordionName];
        dispatch({
            type: SET_SITEMAP_EXPAND_ACCORDION,
            payload: expandAccordionUpdate
        });
    }
    return (
        <>
            <div className="control-panel">
                {groups && groups.length > 0 &&
                    <div className="selection">
                        <span className="selection-lbl">Group By: </span>
                        <Select
                            labelId="table-select-label"
                            label="Group By"
                            id="group-by-select"
                            fontSize="2rem"
                            value={selectedGroup}
                            onChange={handleGroupByChange}
                        >
                            {groups && groups.map(group => {
                                return <MenuItem value={group.code}>{group.value}</MenuItem>
                            })
                            }
                        </Select>
                    </div>
                }
                <div className="header-total-host">Total Hosts: </div>
                <div className="header-counts">
                    {(selectedGroup === UNGROUPED) ?
                        <div className="header-count-item" onClick={() => handleAccordionExpand('')}>
                            <div className="header-count-item-label">{startCase(toLower(UNGROUPED))}</div>
                            <div className="header-count-item-value"><Chip label={accordionData['']?.totalElements || 0} /></div>
                        </div> :
                        Object.keys(groupCount).map((key) => {
                            return <div className="header-count-item" onClick={() => handleAccordionExpand(key)}>
                                <div className="header-count-item-label">{key ? startCase(toLower(key)) : 'NA'}</div>
                                <div className="header-count-item-value"><Chip label={groupCount[key]} /></div>
                            </div>
                        })}
                </div>
                <div className="header-legend">
                    Security Alerts:
                    {securityLegend.map((s) => {
                        const className = 'item ' + s.class
                        return <span className={className}>{s.label}</span>
                    })}
                </div>
                <div className="header-legend">
                    Visibility:
                    {visibilityLegend.map((s) => {
                        const className = 'item ' + s.class
                        return (
                            <span className={className}>
                                {renderIsMonitored({ row: { isMonitored: s.isMonitored } })}
                                {s.label}
                            </span>
                        )
                    })}
                </div>
            </div>
            <div className='netspace-tbl-container'>
                {(selectedGroup === UNGROUPED) ?
                    <NetSpaceTable key={selectedGroup} group={''} />
                    :
                    Object.keys(groupCount).map((key) => {
                        return <NetSpaceTable key={key} group={key} />
                    })}
            </div>
        </>
    );
};

export default ControlPanel;