import { domain_vizr } from "../../../redux/constants/constants";

export const NO_DATA_TO_SHOW_RP = "No Data To Show Here!";
export const CREATE_RP_TEXT = "Click below to create";
export const SET_IS_RISK_CREATED = "SET_IS_RISK_CREATED";
export const GET_ALL_RISK_TYPES = "GET_ALL_RISK_TYPES";
export const GET_ALL_RISK_OWNERS = "GET_ALL_RISK_OWNERS";
export const SET_ALL_RISK_TYPES = "SET_ALL_RISK_TYPES";
export const getAllRisksTypesApi = `${domain_vizr}/tenant/riskTypes`;
export const createRiskPilotApi = `${domain_vizr}/tenant/risk`;
export const getRiskOwnersApi = `${domain_vizr}/tenant/riskOwners`;
export const CREATE_RISK_PILOT = "CREATE_RISK_PILOT";
export const SET_RISK_OWNERS = "SET_RISK_OWNERS";
export const SET_RP_LOADER = "SET_RP_LOADER";
export const CLEAR_RP_DATA = "CLEAR_RP_DATA";
export const GET_ALL_RISK_PILOT_FILES = "GET_ALL_RISK_PILOT_FILES";
export const SET_ALL_RISK_PILOT_FILES = "SET_ALL_RISK_PILOT_FILES";
export const UPDATE_RISK_PILOT_ROW = "UPDATE_RISK_PILOT_ROW";
export const RISK_PILOT_ROW_DETAILS = "RISK_PILOT_ROW_DETAILS";
export const GET_RISK_PILOT_ROW_BY_ID = "GET_RISK_PILOT_ROW_BY_ID";
export const UPDATE_RISK_PILOT_ROW_BY_ID = "UPDATE_RISK_PILOT_ROW_BY_ID";
export const CLEAR_RISK_PILOT_ROW_DETAILS = "CLEAR_RISK_PILOT_ROW_DETAILS";
export const TOTAL_RP_ROW_COUNT = "TOTAL_RP_ROW_COUNT";
export const DELETE_RP_ROW = "DELETE_RP_ROW";
export const SET_IS_RISK_PILOT_ROW_DELETED = "SET_IS_RISK_PILOT_ROW_DELETED";
export const riskPilotRowApi = (id) => {
  return `${domain_vizr}/tenant/risks/${id}`;
};
export const RISK_PILOT_LEVELS = ["HIGH", "MEDIUM", "LOW"];
export const SET_FILTER_RR = "SET_FILTER_RR";
export const SET_FILTER_TRIGGERED = "SET_FILTER_TRIGGERED";
export const CLEAR_FILTERS_RP = "CLEAR_FILTERS_RP";
export const FILTER_RISK_PILOT = "FILTER_RISK_PILOT";

export const ADD_THREAT = "ADD_THREAT";
export const UPDATE_THREAT = "UPDATE_THREAT";
export const DELETE_THREAT = "DELETE_THREAT";
export const ADD_THREAT_VULNERABILITY = "ADD_THREAT_VULNERABILITY";
export const DELETE_THREAT_VULNERABILITY = "DELETE_THREAT_VULNERABILITY";
export const CLEAR_THREATS = "CLEAR_THREATS";

export const ADD_VULNERABILITY = "ADD_VULNERABILITY";
export const UPDATE_VULNERABILITY = "UPDATE_VULNERABILITY";
export const DELETE_VULNERABILITY = "DELETE_VULNERABILITY";
export const ADD_MITIGATION_ID = "ADD_MITIGATION_ID";
export const DELETE_MITIGATION_ID = "DELETE_MITIGATION_ID";
export const CLEAR_VULNERABILITIES = "CLEAR_VULNERABILITIES";

export const ADD_MITIGATION = "ADD_MITIGATION";
export const UPDATE_MITIGATION = "UPDATE_MITIGATION";
export const DELETE_MITIGATION = "DELETE_MITIGATION";
export const CLEAR_MITIGATION = "CLEAR_MITIGATION";

export const RISK_STATUS_DATA = "RISK_STATUS_DATA";
export const MITIGATION_STATUS_DATA = "MITIGATION_STATUS_DATA";
export const THREAT_STATUS_DATA = "THREAT_STATUS_DATA";
export const VULNERABILITY_STATUS_DATA = "VULNERABILITY_STATUS_DATA";

export const UPDATE_FROM_API = "UPDATE_FROM_API";

export const LOW_SEVERITY = "LOW";
export const MEDIUM_SEVERITY = "MEDIUM";
export const HIGH_SEVERITY = "HIGH";
export const CRITICAL_SEVERITY ="CRITICAL";

export const CLEAR_RISK_PILOT_FILES = "CLEAR_RISK_PILOT_FILES";
export const RISK_PILOT = "RISK_PILOT";

export const RISK_PILOT_IMPACT_LEVELS = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];
export const RISK_PILOT_MITIGATION_STATUS = [
  { code: "TODO", value: "To Do", icon: "/riskPilotIcons/mitigationToDo.svg" },
  { code: "OPEN", value: "Open", icon: "/riskPilotIcons/mitigationOpen.svg" },
  {
    code: "IN_PROGRESS",
    value: "In Progress",
    icon: "/riskPilotIcons/mitigationInprogress.svg",
  },
  {
    code: "CLOSED",
    value: "Closed",
    icon: "/riskPilotIcons/mitigationClosed.svg",
  },
];
export const RISK_TABLE_VIEW_OPTIONS = [
  { code: "RISK_VIEW", value: "Risk View" },
  { code: "THREAT_VIEW", value: "Threat View" },
  { code: "VULNERABILITY_VIEW", value: "Vulnerability View" },
  { code: "MITIGATION_VIEW", value: "Mitigation View" },
];

export const getTotalRiskCount = () => {
  return {
    measures: ["RiskPilotRisks.totalRiskCount"],
    timeDimensions: [],
  };
};

export const getAllRiskPilotFilesApi = (offset, limit) => {
  return [
    {
      measures: ["RiskPilotRisks.totalRiskCount"],
      timeDimensions: [],
    },
    {
      measures: [
        "RiskPilotRisks.threatCount",
        "RiskPilotRisks.vulnerabilityCount",
        "RiskPilotRisks.mitigationCount",
        "RiskPilotRisks.toDoCount",
        "RiskPilotRisks.openCount",
        "RiskPilotRisks.closedCount",
        "RiskPilotRisks.inProgressCount",
      ],
      dimensions: [
        "RiskPilotRisks.riskId",
        "RiskPilotRisks.riskType",
        "RiskPilotRisks.severity",
      ],
      order: {
        "RiskPilotRisks.riskId": "asc",
      },
      limit,
      offset,
    },
    {
      measures: [
        "RiskPilotRisks.entitiesWithToDoCount",
        "RiskPilotRisks.entitiesWithOpenCount",
        "RiskPilotRisks.entitiesWithInProgressCount",
        "RiskPilotRisks.entitiesWithClosedCount",
        "RiskPilotRisks.mitigationCount",
      ],
    },
  ];
};

export const getAllThreatFilesApi = (offset, limit) => {
  return [
    {
      measures: ["RiskPilotThreats.totalThreatCount"],
      timeDimensions: [],
    },
    {
      measures: [
        "RiskPilotThreats.toDoCount",
        "RiskPilotThreats.openCount",
        "RiskPilotThreats.vulnerabilityCount",
        "RiskPilotThreats.mitigationCount",
        "RiskPilotThreats.closedCount",
        "RiskPilotThreats.inProgressCount",
      ],
      dimensions: [
        "RiskPilotThreats.riskId",
        "RiskPilotThreats.riskType",
        "RiskPilotThreats.severity",
        "RiskPilotThreats.threatId",
        "RiskPilotThreats.threat",
        "RiskPilotThreats.threatPriority",
      ],
      order: {
        "RiskPilotThreats.toDoCount": "desc",
      },
      offset,
      limit,
    },
    {
      measures: [
        "RiskPilotThreats.entitiesWithToDoCount",
        "RiskPilotThreats.entitiesWithOpenCount",
        "RiskPilotThreats.entitiesWithInProgressCount",
        "RiskPilotThreats.entitiesWithClosedCount",
        "RiskPilotThreats.mitigationCount",
      ],
    },
  ];
};

export const getAllVulnerabilitiesFilesApi = (offset, limit) => {
  return [
    {
      measures: ["RiskPilotVulnerabilities.vulnerabilityTotalCount"],
    },
    {
      measures: [
        "RiskPilotVulnerabilities.mno",
        "RiskPilotVulnerabilities.toDoCount",
        "RiskPilotVulnerabilities.openCount",
        "RiskPilotVulnerabilities.closedCount",
        "RiskPilotVulnerabilities.inProgressCount",
      ],
      dimensions: [
        "RiskPilotVulnerabilities.riskId",
        "RiskPilotVulnerabilities.riskType",
        "RiskPilotVulnerabilities.severity",
        "RiskPilotVulnerabilities.threatId",
        "RiskPilotVulnerabilities.threat",
        "RiskPilotVulnerabilities.threatPriority",
        "RiskPilotVulnerabilities.vulnerabilityId",
        "RiskPilotVulnerabilities.vulnerability",
      ],
      order: {
        "RiskPilotVulnerabilities.mno": "asc",
      },
      offset,
      limit,
    },
    {
      measures: [
        "RiskPilotVulnerabilities.entitiesWithToDoCount",
        "RiskPilotVulnerabilities.entitiesWithOpenCount",
        "RiskPilotVulnerabilities.entitiesWithInProgressCount",
        "RiskPilotVulnerabilities.entitiesWithClosedCount",
        "RiskPilotVulnerabilities.vulnerabilityTotalCount",
      ],
    },
  ];
};

export const getAllMitigationFilesApi = (offset, limit) => {
  return [
    {
      measures: ["RiskPilotMitigations.mitigationCount"],
    },
    {
      dimensions: [
        "RiskPilotMitigations.riskId",
        "RiskPilotMitigations.riskType",
        "RiskPilotMitigations.severity",
        "RiskPilotMitigations.threatId",
        "RiskPilotMitigations.threat",
        "RiskPilotMitigations.vulnerabilityId",
        "RiskPilotMitigations.vulnerability",
        "RiskPilotMitigations.mitigationId",
        "RiskPilotMitigations.mitigation",
        "RiskPilotMitigations.priority",
        "RiskPilotMitigations.status",
        "RiskPilotMitigations.riskOwner",
      ],
      order: {
        "RiskPilotMitigations.riskId": "asc",
      },
      offset,
      limit,
    },
    {
      measures: [
        "RiskPilotMitigations.mitigationCount",
        "RiskPilotMitigations.entitiesWithToDoCount",
        "RiskPilotMitigations.entitiesWithOpenCount",
        "RiskPilotMitigations.entitiesWithInProgressCount",
        "RiskPilotMitigations.entitiesWithClosedCount",
      ],
    },
  ];
};

export const getRiskSummaryDetails = (id) => {
  return {
    "measures": [
      "RiskPilotRisks.threatCount",
      "RiskPilotRisks.vulnerabilityCount",
      "RiskPilotRisks.mitigationCount",
      "RiskPilotRisks.toDoCount",
      "RiskPilotRisks.openCount",
      "RiskPilotRisks.closedCount",
      "RiskPilotRisks.inProgressCount"
    ],
    "dimensions": [
      "RiskPilotRisks.riskId",
      "RiskPilotRisks.riskType",
      "RiskPilotRisks.severity"
    ],
    "order": {
      "RiskPilotRisks.riskId": "asc"
    },
    "filters": [
      {
        "member": "RiskPilotRisks.riskId",
        "operator": "equals",
        "values": [
          `${id}`
        ]
      }
    ]
  }
};

export const getRiskDetailsById = (id) => {
  return `${domain_vizr}/tenant/risk/${id}`;
};

export const mitigationOwnerLookup = {
  IT_SECURITY_TEAM: "IT Security Team",
  HR_AND_IT_SECURITY_TEAM: "HR And IT Security Team",
  IT_OPERATIONS: "IT Operations",
  DEVELOPMENT_TEAM: "Development Team",
  MARKETING_AND_IT_SECURITY_TEAM: "Marketing & IT Security Team",
  IT_GOVERNANCE_TEAM: "IT Governance Team",
  FACILITIES_MANAGEMENT: "Facilities Management",
  COMPLIANCE_TEAM: "Compliance Team",
};

export const riskTypeLookup = {
  DATA_EXFILTRATION: "Data Exfiltration",
  DATA_LOCKUP: "Data Lockup",
  FINANCIAL_LOSS_ECONOMIC_IMPACT: "Financial Loss/Economic Impact",
  OPERATIONAL_OUTAGE_DISRUPTION: "Operational Outage/Disruption",
  BRAND_OR_REPUTATION_IMPACT: "Brand or Reputation Impact",
  REGULARITY_OR_COMPLIANCE: "Regularity or Compliance",
  DATA_GOVERNANCE: "Data Governance",
};

export const severityThreatsLookUp = {
  CRITICAL: "Critical Threats",
  HIGH: "High Threats",
  MEDIUM: "Medium Threat",
  LOW: "Low Threats",
};

export const RiskSeverityLookUp = {
  CRITICAL: "Critical",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};

// Used for RiskPilot Risks Table
export const riskFields = [
  { name: "severity", key: "severity", type: "string" },
  { name: "riskType", key: "riskType", type: "string" },
  { name: "riskId", key: "riskId", type: "string" },
  { name: "openCount", key: "openCount", type: "int" },
  { name: "vulnerabilityCount", key: "vulnerabilityCount", type: "int" },
  { name: "mitigationCount", key: "mitigationCount", type: "int" },
  { name: "inProgressCount", key: "inProgressCount", type: "int" },
  { name: "toDoCount", key: "toDoCount", type: "int" },
  { name: "closedCount", key: "closedCount", type: "int" },
  { name: "threatCount", key: "threatCount", type: "int" },
];

// Used for RiskPilot Threats Table
export const threatFields = [
  { name: "threatId", key: "threatId", type: "string" },
  { name: "severity", key: "severity", type: "string" },
  { name: "threat", key: "threat", type: "string" },
  { name: "riskType", key: "riskType", type: "string" },
  { name: "riskId", key: "riskId", type: "string" },
  { name: "openCount", key: "openCount", type: "int" },
  { name: "vulnerabilityCount", key: "vulnerabilityCount", type: "int" },
  { name: "mitigationCount", key: "mitigationCount", type: "int" },
  { name: "inProgressCount", key: "inProgressCount", type: "int" },
  { name: "toDoCount", key: "toDoCount", type: "int" },
  { name: "closedCount", key: "closedCount", type: "int" },
  { name: "threatPriority", key: "threatPriority", type: "string" },
];

export const vulnerabilityFields = [
  { name: "riskId", key: "riskId", type: "string" },
  { name: "severity", key: "severity", type: "string" },
  { name: "threatId", key: "threatId", type: "string" },
  { name: "threat", key: "threat", type: "string" },
  { name: "vulnerabilityId", key: "vulnerabilityId", type: "string" },
  { name: "vulnerability", key: "vulnerability", type: "string" },
  { name: "riskType", key: "riskType", type: "string" },
  { name: "openCount", key: "openCount", type: "int" },
  { name: "inProgressCount", key: "inProgressCount", type: "int" },
  { name: "toDoCount", key: "toDoCount", type: "int" },
  { name: "closedCount", key: "closedCount", type: "int" },
  { name: "mitigationCount", key: "mno", type: "int" },
  { name: "threatPriority", key: "threatPriority", type: "string" },
];

export const mitigationFields = [
  { name: "owner", key: "riskOwner", type: "string" },
  { name: "riskId", key: "riskId", type: "string" },
  { name: "severity", key: "severity", type: "string" },
  { name: "threatId", key: "threatId", type: "string" },
  { name: "threat", key: "threat", type: "string" },
  { name: "status", key: "status", type: "string" },
  { name: "vulnerabilityId", key: "vulnerabilityId", type: "string" },
  { name: "vulnerability", key: "vulnerability", type: "string" },
  { name: "mitigationId", key: "mitigationId", type: "string" },
  { name: "mitigation", key: "mitigation", type: "string" },
  { name: "riskType", key: "riskType", type: "string" },
  { name: "priority", key: "priority", type: "string" },
];

export const riskTypeIcons = {
  DATA_EXFILTRATION: "/riskPilotIcons/dataExfiltration.svg",
  DATA_LOCKUP: "/riskPilotIcons/DataLock.svg",
  FINANCIAL_LOSS_ECONOMIC_IMPACT: "/riskPilotIcons/financial.svg",
  OPERATIONAL_OUTAGE_DISRUPTION: "/riskPilotIcons/operational.svg",
  BRAND_OR_REPUTATION_IMPACT: "/riskPilotIcons/Brand.svg",
  REGULARITY_OR_COMPLIANCE: "/riskPilotIcons/compliance.svg",
  DATA_GOVERNANCE: "/riskPilotIcons/DataGovernance.svg",
};
export const RISK_SNACKBAR_MESSAGE = "RISK_SNACKBAR_MESSAGE"
export const RISK_SUCCESS_MESSAGE = "Risk saved successfully."
export const RISK_SAVE_UNSUCCESSFUL_MESSAGE = "Risk not saved. Please try again."
export const GET_RISK_SUMMARY_DETAILS = "GET_RISK_SUMMARY_DETAILS";
export const SET_RISK_SUMMARY_DETAILS = "SET_RISK_SUMMARY_DETAILS";
export const RISK_SUMMARY_DETAILS_FETCH_ERROR = "Unable to fetch the Risk summary Details";
export const SELECTED_ROW_ID = "SELECTED_ROW_ID";

export const RISK_LEVEL_LOOKUP = {
  CRITICAL: "Critical Risk Level",
  HIGH: "High Risk Level",
  MEDIUM: "Medium Risk Level",
  LOW: "Low Risk Level"
}

export const getMitigationStatusData = {
  measures: [
    "RiskPilotMitigations.mitigationCount",
    "RiskPilotMitigations.entitiesWithToDoCount",
    "RiskPilotMitigations.entitiesWithOpenCount",
    "RiskPilotMitigations.entitiesWithInProgressCount",
    "RiskPilotMitigations.entitiesWithClosedCount",
  ],
};

